.tabs-wraper {

    &__title {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 40px;
        border-bottom: 1px solid $white;
        padding-bottom: 5px;
        height: 20px;
    }

    &__btn {
        background: transparent;
        border: none;
        outline: none;
        color: $white;
        text-transform: uppercase;
        font-size: 0.8rem;
        margin-right: 5px;
        cursor: pointer;

        &--tab {
            position: relative;

            &-active::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                background: white;
                left: 0;
                bottom: -7px;
                ;
            }
        }
    }

    &__content {
        min-height: 100px;
        margin-top: 15px;
    }
}

.tab-message {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap;

    &__text {
        resize: none;
        width: 100%;
        border-radius: 10px;
        padding: 10px;
        border: none;
        font-size: 12px;
        height: 100px;
        text-transform: initial;

        &--main {
            width: 100%
        }

        &--title {
            width: 25%;
        }
    }
}

.tabs-settings {


    * {
        color: $main-color !important;
    }

    .input-label {
        color: white !important;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__input {
        width: 49%;
        padding: 5px 15px;
        border-radius: 10px;
        border: none;
        outline: none;
        margin: 5px 0;
        min-height: 35px;
        font-size: 10px;
    }

    label {
        font-size: 10px;
    }

    &__select {
        font-size: 10px;
        margin: 5px 0;
        width: 49%;

        .Select {
            width: 49%;
        }

        .Select-control {
            border-radius: 10px;
            border: none !important;

        }
    }
}

.gmail-container {
    margin: 8px 0px;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    text-align: left;
    width: 100%;

    input {
        width: 100%;
    }

}

.inputs-container {
    width: 100%;
    display: flex;

    .Select {
        width: 100%;
        margin: 5px;
    }
}

.gmailError {
    margin-left: 10px;
}

.gmail-annotation {
    text-align: center;
    padding: 0px 15px;
}

.gmail-annotation-text {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
}

.vocative-wrapper {
    position: absolute;
    top: 0px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 800;
}

.vocative {
    background: #EEF2F0;
    border-radius: 5px;
    font-size: 8px;
    padding: 5px;
    color: #11C4D7;
    margin: 0px 4px;
    border: none;

    &:hover {
        cursor: pointer;
    }
}

#messageContent {
    padding-top: 25px;
    width: 100%;
    text-transform: initial;
}

#messageContent2 {
    padding-top: 25px;
    width: 100%;
    text-transform: initial;
}

.text-area-wrapper {
    width: 70%;
    position: relative;
}


@media screen and (max-width: $break-medium) {

    .gmail-annotation-text {
        color: white;
        text-transform: uppercase;
        font-size: 10px;
    }

    .label-left {
        font-size: 8.5px;
    }

    .label-right {
        font-size: 8.5px;
    }

    .text-area-wrapper {
        width: 100%;
        position: relative;
    }

    .tabs-wraper {
        &__title {
            margin-top: 50px;
        }

        &__btn {
            font-size: 0.5rem;
        }
    }

    .vocative-wrapper {
        position: absolute;
        top: 0px;
        right: 3px;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }



    .tab-message {
        &__text {
            height: 100px;
            width: 300px;
            text-transform: initial !important;

            &--main {
                width: 100%;
                text-transform: initial !important;
            }

            &--title {
                width: 100%;
                margin-top: 10px;
                text-transform: initial !important;
            }
        }
    }

    .tabs-settings {
        &__input {
            font-size: 8.5px;
        }

        label {
            font-size: 8.5px;
        }

        &__select {
            font-size: 8.5px;

            .Select {
                font-size: 8.5px;
            }

            .Select-control {
                font-size: 8.5px;
            }

        }
    }


    .tab-message {

        &__text {
            width: 100%;

            &--main {
                width: 100%
            }

            &--title {
                width: 100%;
            }
        }
    }

    .gmailError {
        font-size: 8px !important;
        top: -8px;
    }
}