.chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 150px;
    opacity: 0;

    .chart-title {
        margin-bottom: 20px;
    }
}

.chart-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__percent-use {
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__details {
        font-size: 10px;
        margin-top: 5px;
        line-height: 1.3;
    }
}

@media screen and (max-width: $break-medium) {
    .chart-circle {
        &__details {
            font-size: 8px;
        }
    }

    .chart-wrapper {
        font-size: 0.6rem;

        width: 90px;

        .chart-title {
            margin-bottom: 10px;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}