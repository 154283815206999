.modal-wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shadow-layer {
    background: rgba(255, 255, 255, .9);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-outer {
    position: fixed;
    width: 600px;
    height: auto;
    background-color: $main-color;
    color: $white;
    padding: 20px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    transform: translate(-50%, -50%);

    p {
        margin: 20px 0;
    }

    &__btn {
        background: transparent;
        border: 1px solid $white;
        border-radius: 10px;
        padding: 10px;
        width: 200px;
        color: $white;
        margin: 20px auto 0;
        display: block;
        outline: none;
        text-decoration: none;
        text-align: center;
    }
}

@media screen and (max-width: $break-medium) {


    .modal-outer {
        width: 95%;
        height: auto;
        padding: 10px;
        border-radius: 10px;

        font-size: 0.8rem;

        p {
            margin: 20px 0;
        }

        &__btn {
            padding: 7px;
            width: 150px;
            font-size: 0.8rem;
            text-align: center;

        }
    }
}

* {
    outline: none;
}