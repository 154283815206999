.select-search-option {
    width: 98px;
    font-size: 8.8px;
    color: white;
    z-index: 9999;

    .Select-control,
    .Select-input {
        height: 25px;
        margin-top: 7px;
        border: none;
    }

    .Select-placeholder {
        line-height: 20px;
    }

    .Select-value {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .Select-arrow-zone {
        padding: 0;
        position: absolute;
        top: 62%;
        right: -3px;
        transform: translate(0, -62%);
    }
}

@media screen and (max-width: 768px) {
    .select-search-option {
        width: 110px;

        .Select-control,
        .Select-input {
            height: 25px;
            margin-top: 2px;
            border: none;
        }
    }


}