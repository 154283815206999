// colors
$main-color: #12c4d8;
$white: #fff;
$black: #000;
$dark-text-color: #656565;
$gray: #8b8b8b;


%gradient-to-left {
    background: rgba(18, 196, 216, 1);
    background: -moz-linear-gradient(left, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(18, 196, 216, 1)), color-stop(100%, rgba(104, 229, 240, 1)));
    background: -webkit-linear-gradient(left, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: -o-linear-gradient(left, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: -ms-linear-gradient(left, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: linear-gradient(to right, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
}

%gradient-to-right {
    background: rgba(104, 229, 240, 1);
    background: -moz-linear-gradient(left, rgba(104, 229, 240, 1) 0%, rgba(18, 196, 216, 1) 80%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(104, 229, 240, 1)), color-stop(80%, rgba(18, 196, 216, 1)));
    background: -webkit-linear-gradient(left, rgba(104, 229, 240, 1) 0%, rgba(18, 196, 216, 1) 80%);
    background: -o-linear-gradient(left, rgba(104, 229, 240, 1) 0%, rgba(18, 196, 216, 1) 80%);
    background: -ms-linear-gradient(left, rgba(104, 229, 240, 1) 0%, rgba(18, 196, 216, 1) 80%);
    background: linear-gradient(to right, rgba(104, 229, 240, 1) 0%, rgba(18, 196, 216, 1) 80%);
}

%gradient-to-down {
    background: rgba(18, 196, 216, 1);
    background: -moz-linear-gradient(top, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(18, 196, 216, 1)), color-stop(100%, rgba(104, 229, 240, 1)));
    background: -webkit-linear-gradient(top, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: -o-linear-gradient(top, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: -ms-linear-gradient(top, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
    background: linear-gradient(to bottom, rgba(18, 196, 216, 1) 0%, rgba(104, 229, 240, 1) 100%);
}
