.results-item {
    margin: 10px 0;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    font-size: 13px;

    &:last-child {
        margin-bottom: 50px;
    }

    &__circle {
        @extend %gradient-to-right;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-right: 20px;
        margin-left: 10px;
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__details--name {
        margin: 5px 0;
        color: $main-color;
    }

    &__wrapper-action {
        display: flex;
    }

    &__action {
        display: flex;
        align-items: center;
        color: $main-color;
    }

    &__action-img {
        max-width: 35px;
        margin: 0 20px 0 15px;
        cursor: pointer;

        &--disable {
            cursor: pointer;
            opacity: .4;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .results-item {
        margin: 10px 0;
        padding: 10px;

        p {
            font-size: 0.8rem
        }

        &__circle {
            display: none;
        }

        &__details--name {
            margin: 5px 0;
            color: $main-color;
        }


        .results-item__info {
            .text-bold {
                font-size: 9px !important;
            }
        }

        .text-center {
            background: red !important;
        }

        &__wrapper-action {
            display: flex;

            img {
                max-width: 30px;
            }
        }

        &__action {
            p {
                display: none;
            }
        }

        &__action-img {
            max-width: 30px;
            margin: 0;
            margin-right: 2px;
            margin-left: 8px;
        }
    }
}