.search-section {
    background: #11C4D7;
    position: relative;
    padding: 10px 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

    &--campaign {
        margin: 40px 30px 10px;
    }

    &__title-wrapper {
        border-bottom: 1px solid $white;
        padding-bottom: 10px;
    }

    &__title {
        margin-bottom: 5px;
    }

    &__subtitle {
        @include font-size(0.7);
    }

    .App__close-btn {
        position: fixed;
        top: 10px;
        right: 10px;
    }

}

@media screen and (max-width: $break-medium) {
    .search-section {
        padding: 10px 15px 15px;
        margin-bottom: 20px;

        &__title {
            max-width: 150px;
            font-size: .6rem;
        }

        &__title-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        &--campaign {
            margin: 40px 10px 5px;
        }
    }


}