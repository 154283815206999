.input-error {
    color: white;
    font-size: 11px;
    letter-spacing: -0.2px;
    margin-left: 5px;
    padding-top: 8px;
}

.search-wrapper__item {
    margin-bottom: 4px !important;
    margin-top: 5px !important;
}

.content-header {
    margin: 120px auto 50px;
}

.content-header__title {
    margin-bottom: 0 !important;

    &:nth-child(2) {
        margin-top: 10px;
    }
}

.input-label {
    color: white;
    font-size: 9.5px;
    letter-spacing: -0.2px;
    margin-left: 5px;
}

.search-wrapper input {
    margin-top: 4px;
}

.search-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 0 0;
    position: relative;

    &__item {
        display: flex;
        flex-direction: column;
        margin: 5px 0;

        &--half {
            flex-basis: 330px;

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    &__input {
        width: 100%;
        max-width: 670px;
        padding: 12px;
        border-radius: 10px;
        border: none;
        outline: none;
        font-size: 0.7rem;
    }

    &__container-action {
        height: 100%;
        position: absolute;
        right: -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.5s transform ease-in-out;
        width: 150px;

        &:hover {
            transform: scale(1.1);
        }

        &--smaller {
            transform: scale(0.93);

            &:hover {
                transform: scale(0.98);
            }
        }
    }

    &__btn-submit {
        outline: none;
        border: none;
        background-color: transparent;

    }

    &__image_submit {
        max-width: 50%;
        transform: scale(1.3);
    }

}

@media screen and (max-width: 850px) {

    .search-wrapper {

        &__item {

            &--half {
                flex-basis: 300px;
            }
        }

        &__input {
            max-width: 610px;
        }

        &__container-action {
            right: -25px;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .search-wrapper {
        margin-top: 10px;
        max-width: unset;

        &__double-item {
            flex-wrap: wrap;
            flex-direction: column;
        }

        &__input {
            padding: 10px;
            max-width: unset;
        }

        &__image_submit {
            max-width: 50%;
            transform: scale(0.9);

            &:hover {
                cursor: pointer;
            }
        }

        &__item {
            &--half {
                width: 100%;
                margin-right: 0px;
                flex: 1;
            }
        }

        &__container-action {
            position: relative;
            background-color: transparent;
            width: 100%;
            right: 0;
            border: 2px solid $white;
            border-radius: 10px;
            flex-direction: row;
            margin-top: 10px;

            &:hover {
                transform: scale(1.02);
                cursor: pointer;
            }

            &--smaller {
                transform: scale(1);

                &:hover {
                    transform: scale(0.98);
                }
            }

        }
    }
}