@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,800&subset=latin-ext');


* {
    font-family: 'Work Sans', sans-serif;
}

@mixin font-size($size) {
    font-size: $size + rem;
}

.text-small-xs {
    @include font-size(0.3)
}

.text-small {
    @include font-size(0.5)
}

.text-normal {
    @include font-size(.8)
}

.text-medium {
    @include font-size(1)
}

.text-big {
    @include font-size(1.4)
}

.text-big-xl {
    @include font-size(2.3)
}

.text-to-top {
    vertical-align: top;
}

.text-main-color {
    color: $main-color
}

.text-white {
    color: $white;
}

.text-gray {
    color: $gray;
}

.text-dark {
    color: $dark-text-color;
}

.text-bold {
    font-weight: 700;
}

.text-semi-bold {
    font-weight: 500;
}

.text-light {
    font-weight: 300;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-height {
    line-height: 2;
}

.text-height-medium {
    line-height: 1.5;
}

@media screen and (max-width: $break-medium) {
    .text-small-xs {
        @include font-size(0.2)
    }

    .text-small {
        @include font-size(0.4)
    }

    .text-medium {
        @include font-size(0.6)
    }

    .text-big {
        @include font-size(.9)
    }

    .text-big-xl {
        @include font-size(1.4)
    }

    .text-normal {
        @include font-size(0.7)
    }

}
