.search-option {
    position: absolute;
    top: -35px;

    &--0 {
        left: 30px;
    }

    &--1 {
        left: 130px;
    }

    &--2 {
        left: 230px;
    }


    &__circle {
        background: #d9f6f9;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-bottom: 5px;

        &:hover img {
            transform: scale(1.4);
        }

        &--active {
            background: #11C4D7;
        }

        &--inner {
            background: white;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            padding: 10px;

            &:hover {
                cursor: pointer;
            }

            img {
                padding-top: 1px;
                transform: scale(1.1);
            }
        }

        &--shadow {
            position: absolute;
            z-index: -1;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        }
    }

    &__text {
        font-size: 0.6rem;
        white-space: nowrap;
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.clickedImage {
    img {
        transform: scale(1.4);
    }
}

@media screen and (max-width: $break-medium) {

    .search-option {
        max-width: 60px;

        &--0 {
            left: 10px;
        }

        &--1 {
            left: 80px;
        }

        &--2 {
            left: 150px;
        }

        &__circle {
            height: 60px;
            width: 60px;

            &:hover img {
                transform: scale(1);
            }

            &--inner {
                width: 40px;
                height: 40px;
                padding: 5px;

                img {
                    padding-top: 1px;
                    transform: scale(0.8);
                }
            }

            &--shadow {
                height: 60px;
                width: 60px;
            }
        }

        &__text {
            font-size: 0.55rem;
            letter-spacing: -0.5px;
        }
    }

    .clickedImage {
        img {
            transform: scale(1);
        }
    }

}