@keyframes slideInFooter {
    0% {
        max-height: 192px;
    }

    100% {
        max-height: 800px;
    }
}

.footer-wrapper {
    position: relative;
    box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.2);

    &__circle {
        transform: translate(50%, -50%);
        position: absolute;
        right: 50%;

        &--wrapper {
            height: 125px;
            width: 125px;
            margin-bottom: 5px;
            background-color: $white;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                z-index: -99;
                top: 10px;
                width: 60%;
                height: 60%;
                border-radius: 50%;
                box-shadow: 0px -5px 15px 10px rgba(0, 0, 0, 0.2);

            }
        }

        &--inner {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-color: $main-color;
            padding: 20px;
        }

        &--shadow {
            position: absolute;
            z-index: -1;
            height: 125px;
            width: 125px;
            border-radius: 50%;
        }
    }
}

.login-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;

    &__form,
    &__access {
        width: 35%;
    }

    &__form {
        margin-left: 5rem;
    }

    &__access {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__input {
        border: 1px solid $main-color;
        border-radius: 10px;
        display: block;
        padding: 10px;
        margin: 10px 0;
        width: 100%;
    }

    &__btn {
        border: none;
        padding: 10px 20px;
        margin: 0 auto;
        border-radius: 10px;
        background-color: $main-color;
        color: $white;
        display: block;
        cursor: pointer;

        &--left {
            width: 80%;
            margin: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

    }
}

.user-wrapper {
    padding: 60px 0;
    transition: 3s all ease-in-out;

    .user-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;

        &__btn {
            border: none;
            padding: 10px 20px;
            border-radius: 10px;
            background-color: $main-color;
            color: $white;
            display: block;
            cursor: pointer;
            min-width: 200px;

            &--left {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                position: absolute;
                right: 0;
            }

            &--right {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &__details {
            margin-top: 20px;
            text-align: center;
        }
    }
}

.charts-wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

@media screen and (max-width: $break-medium) {
    @keyframes slideInFooter {
        0% {
            max-height: 242px;
        }

        100% {
            max-height: 800px;
        }
    }

    .footer-wrapper {
        &__circle {
            &--wrapper {
                height: 95px;
                width: 95px;
            }

            &--inner {
                height: 80px;
                width: 80px;
            }

            &--shadow {
                height: 95px;
                width: 95px;
            }
        }
    }

    .user-wrapper {
        .user-header {
            flex-wrap: wrap;

            &__btn {
                width: 100%;
                border-radius: 0;
                margin: 5px 0;
            }

            &__details {
                margin-top: 20px;
                text-align: center;
            }
        }
    }

    .login-wrapper {
        padding: 60px 0 20px 0;
        flex-wrap: wrap;

        &__form,
        &__access {
            width: 100%;
        }

        &__form {
            margin-left: 0rem;
            text-align: center;
            margin: 10px;
        }

        &__btn {

            &--left {
                width: 100%;
                text-align: center;
                border-radius: 0;
            }

        }
    }
}

.premium-info {
    background: #11C4D7;
    width: 100%;
    position: fixed;
    bottom: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-weight: bold;
    font-size: 15px;

    a {
        text-decoration: none;
        color: white;
        text-transform: uppercase;
    }

    .howto {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 12px;
    }

    .info-container {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);

        .info {
            font-size: 12px;
            margin: 0px 10px;
        }
    }
}

@media screen and (max-width: $break-medium) {
    .premium-info {
        height: 40px;

        a {
            font-size: 10px;
        }
    }

    .howto {
        display: none;
    }

    .info-container {
        display: none;
    }

    .App__container {
        margin-bottom: 50px;
    }
}

@keyframes slideInPremium {
    0% {
        bottom: -50px;
    }

    100% {
        bottom: 0;
    }
}