.nav-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
    min-height: 65px;
    margin-top: 100px;

    .sendingEmails {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hiddenDiv {
        opacity: 0;
    }

    &__info {
        @include font-size(1);
    }

    &__btn-wrapper {
        display: flex;
        position: relative;
        justify-content: space-between;

        &--expand {
            justify-content: flex-end;
        }

        .text-center {
            margin-left: 20px;
        }
    }

    &__btn {
        @extend %gradient-to-left;
        color: $white;
        cursor: pointer;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
    }

    &__btn--left {
        padding: 10px 15px 10px 20px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-right: 20px;
    }

    &__btn--right {
        padding: 10px 15px 10px 20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &__btn-send--mobile {
        display: none;
    }

    &__btn-send {
        position: absolute;
        left: 50%;
        top: 5px;
        transform: translate(-50%, 0%);
        border: none;
        background-color: $main-color;
        color: $white;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
        cursor: pointer;
    }

    &__logo-wrapper {
        position: absolute;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        background-color: $white;
        color: $black;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
    }

    &__logo-inner {
        position: relative;
        height: 100%;
    }

    &__logo {
        position: absolute;
        bottom: 12px;
        max-width: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__user-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

        &__image {
            max-width: 50px;
            margin: 0 10px;
        }
    }

    &__progress-bar {
        display: none;
        position: absolute;
        top: 0;
        z-index: -1;

    }

}

.content-header {
    margin: 125px auto 40px;
    width: 70%;
    text-align: center;

    &__title {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: $break-medium) {
    .content-header {
        margin: 100px auto 50px;
        width: 90%;
        font-size: 0.8rem;
    }

    .nav-header {

        &__btn {
            min-height: 45px;
        }

        &__btn-wrapper {
            width: 60%;
        }

        &__btn--left {
            padding: 10px 10px 10px 10px;
        }

        &__btn--right {
            padding: 10px 10px 10px 10px;
        }

    }

}

@media screen and (max-width: $break-medium) {
    .nav-header__logo-wrapper {
        width: 140px;
        height: 140px;

        .nav-header__btn-send {
            width: 55px;
            height: 55px;
            font-size: 10px;
        }
    }

    .nav-header__login-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .nav-header__btn--right {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px !important;
        margin: 0;
        padding: 0;
    }

    .nav-header__btn-wrapper {

        .text-center {
            position: absolute;
            margin-left: 14px;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .nav-header__info {
            font-size: 10px !important;
        }

        .text-big {
            font-size: 11px;
        }
    }
}