body {
    overflow-x: hidden;
}

@keyframes toggleFade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.App {

    position: relative;
    margin-top: 100px;

    button {
        outline: none;
    }

    * {
        text-transform: uppercase;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: initial;
        /* Safari */
        -khtml-user-select: initial;
        /* Konqueror HTML */
        -moz-user-select: initial;
        /* Firefox */
        -ms-user-select: initial;
        /* Internet Explorer/Edge */
        user-select: initial;
    }

    &__container {
        max-width: 850px;
        margin: 0 auto;
        position: relative;
    }

    &__close-btn {
        position: absolute;
        width: 50px;
        top: 5px;
        right: 5px;

        &--bg {
            padding: 3px;
            background-color: $white;
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }

        &--login {
            top: 10px;
            right: 10px;
        }
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $main-color;
        opacity: 1;
        /* Firefox */
    }

    button:disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    .layer-wrapper {
        position: fixed;
        width: 100%;
        background: $white;
        z-index: 999;
        transition: all 1s ease-in-out;

        &--top {
            top: 0;
            transform: translateY(-1500px);
        }

        &--bottom {
            bottom: -100px;
            transform: translateY(400px);

            &--show {
                transform: translateY(-80px);
            }
        }
    }
}

.page-block-scroll {
    overflow-y: hidden;
}

.player-wrapper {
    position: relative;
    padding-top: 393px / 1024px * 100%;
    margin: 20px 0;
    height: auto;
    max-height: 600px;
    transition: max-height 0.3s ease-out;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .results-search__wrapper-campaign {
        margin-right: 0px !important;
    }
}

@media screen and (max-width: $break-medium) {
    .App__container {
        padding: 0 10px;
        margin-bottom: 80px !important;
    }

    .App {
        &__close-btn {
            width: 35px;
            z-index: 999;

            &--bg {
                width: 35px;
                height: 35px;
            }
        }
    }
}

.form__select {
    margin-top: 10px;
    font-size: 12px;
    color: black;
    border: none;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    p {
        margin: 0;
        padding: 0;
        line-height: 1;
    }
}

.boldText {
    font-weight: 900;
    font-size: 21px;
    max-width: 400px;
    margin: 0px 0px !important;
    margin-top: 20px !important;
    margin-bottom: 5px !important;
}

.thickText {
    font-weight: bold;
    max-width: 250px;
    font-size: 12px;
    margin: 0px !important;
    margin-bottom: 10px !important;
}

.mediumText {
    font-size: 19px;
}

.smallText {
    line-height: 1.5;
    font-size: 12px;
    font-weight: bold;
}

.smallerFont {
    font-size: 11px;
}

.centerinputs {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.calendarWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 9px;
    padding: 8px;
    max-width: calc(50% - 8px);
    margin-bottom: 4px;
    width: 40px;

    label {
        font-size: 8px;
        text-align: center;
    }

    .rw-widget-picker {
        height: 25px;
        font-size: 15px;
    }

    .rw-widget-picker {
        height: auto;
        font-size: 13px;
    }

    .rw-popup-container {
        font-size: 12px;
    }

    .calendar {
        font-size: 10px;
        border: none;
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }

    .react-datepicker {
        font-size: 10px;
    }

}

.patternRadio {
    display: none;
}

.pattern {
    &:hover {
        cursor: pointer;
    }
}

.patternRadio:checked+label>.pattern {
    border: 2px solid white;
}

.CircularProgressbar {
    width: 150px;
    height: 150px;
    vertical-align: middle;
}

.shadow-layer {
    transition: 1s opacity ease-in-out;
}

@media screen and (max-width: $break-medium) {

    .emails-annotation {
        font-size: 10px !important;
        margin-top: -15px;
    }

    .calendarWrapper {
        max-width: calc(50% - 4px);
    }

    .calendarWrapper {
        .rw-widget-picker {
            font-size: 10px;
        }
    }

    .form__select {
        margin-top: 2px;

    }

    .CircularProgressbar {
        width: 90px;
        height: 90px;
        vertical-align: middle;
    }
}



.CircularProgressbar .CircularProgressbar-path {
    stroke: white;
    stroke-linecap: square;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #6DD9E6;
    stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: white;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
}


@media screen and (max-width: $break-medium) {
    .nav-header__btn-wrapper {

        width: auto !important;

        .text-center {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }


        p {
            font-size: 14px;
        }

        span {
            font-size: 9px !important;
            margin: 0px 2px;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 10px;
            padding-right: 20px;
            min-height: 30px;
            position: absolute;
            top: -40px;
        }
    }

    .nav-header__btn {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-top-right-radius: 0 !important;

        p {
            font-size: 7.5px !important;
        }
    }

    .credits {
        display: none;
    }

    .smallerFont {
        font-size: 7px !important;
    }

}

.Select-control .Select-value-label {
    color: #11C4D7 !important;
}

.Select-menu-outer * {
    color: #11C4D7 !important;
}

.Select-placeholder {
    color: #11C4D7 !important;

}

.labels-wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .label-left {
        flex: 1;
        margin-bottom: 1px;
        margin-top: 1px;
    }

    .label-right {
        flex: 1;
        margin: 1px 0px;
        margin-left: 20px;
    }

    .label-hidden {
        opacity: 0;
        display: none;
    }
}

.signature {
    margin-bottom: 4px;
}