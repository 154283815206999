.results-search {

    &__wrapper-search {
        padding: 10px 0;
    }

    &__wrapper-campaign {
        padding: 5px 30px;
    }

    &__summary {
        position: relative;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        padding: 10px 10px 10px 30px;
        border-radius: 10px;
    }

    &__prefix {
        position: absolute;
        height: 100%;
        width: 20px;
        @extend %gradient-to-down;
        display: block;
        top: 0;
        left: 0;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;

    }

    .text-dark {
        font-size: 15px;
    }

    &__count-details {
        margin-top: 10px;
        color: $main-color;
        font-size: 13.3px;

        p:first-child() {
            margin: 2px 0px;
        }

        span {
            margin-right: 10px;
        }
    }
}


@media screen and (max-width: $break-medium) {
    .results-search {
        margin: 5px 0;

        &__wrapper-search {
            padding: 30px 0;
        }

        &__wrapper-campaign {
            padding: 5px 10px;
            margin-right: 12px;
        }

        &__summary {
            padding: 10px 10px 15px 25px;
            font-size: 0.7rem;
        }

        &__prefix {
            width: 15px;
        }

        &__count-details {
            margin-top: 20px;

            span {
                margin-right: 0px;
            }
        }
    }
}